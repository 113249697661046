/**
 * Kovax
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

/**
 * GSAP controller.
 */
export default class extends Controller {
  static values = {
    type: String,
    direction: String,
  };

  /**
   * GSAP context.
   */
  gsapContext = gsap.context(() => {});

  /**
   * Connect.
   */
  connect() {
    this.gsapContext.add(() => {
      // Scroll image animation
      if (this.typeValue === 'image') {
        const target = this.element.querySelector('img');

        if (target) {
          const scaleFactor = 1.1;
          const originalHeight = target.offsetHeight;
          const scaledHeight = originalHeight * scaleFactor;
          const offset = (scaledHeight - originalHeight) / 2;

          gsap.fromTo(target, {
            y: this.directionValue === 'down' ? -offset : offset,
            scale: scaleFactor,
          }, {
            y: this.directionValue === 'down' ? offset : -offset,
            scale: scaleFactor,
            force3D: true,
            scrollTrigger: {
              trigger: this.element,
              scrub: 1,
            },
          });
        }
      }

      // Text animation
      if (this.typeValue === 'fade') {
        gsap.fromTo(this.element, {
          opacity: 0.25,
        }, {
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            once: true,
            trigger: this.element,
            start: 'top 80%',
          },
        });
      }

      // Spinner animation
      if (this.typeValue === 'spinner') {
        gsap.fromTo(this.element, {
          rotate: 0,
        }, {
          rotate: 360,
          force3D: true,
          scrollTrigger: {
            trigger: this.element,
            scrub: 1,
          },
        });
      }

      // Circle animation
      if (this.typeValue === 'circle') {
        gsap.to(this.element, {
          y: () => -window.innerHeight / 2,
          transformOrigin: 'left',
          scale: 1.05,
          force3D: true,
          scrollTrigger: {
            trigger: this.element,
            scrub: 1.5,
          },
        });
      }
    });
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.revert();
  }
}
