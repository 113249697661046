/**
 * Kovax
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * Request form submit.
   *
   * @param {Event} e
   */
  requestSubmit(e) {
    if (!e.target.name) {
      e.target.form.action = e.target.value;
    }

    this.element.requestSubmit();
  }
}
