/**
 * Kovax
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Tabs controller.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['button', 'content'];

  /**
   * Toggle tab.
   *
   * @param {Event} event
   */
  toggle(event) {
    this.contentTargets.forEach((target, key) => {
      target.classList.toggle(this.activeClass, key === event.params.key);
      this.buttonTargets[key].classList.toggle(this.activeClass, key === event.params.key);
    });
  }
}
