/**
 * Kovax
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';

/**
 * Controller for gallery.
 */
export default class extends Controller {
  static values = {
    selector: {
      type: String,
      default: 'a[href]',
    },
    caption: {
      type: Boolean,
      default: true,
    },
  };

  /**
   * LightGallery instance.
   */
  lg;

  /**
   * Connect gallery.
   */
  connect() {
    this.lg = lightGallery(this.element, {
      licenseKey: '00000000-00000000-00000000-00000000',
      download: false,
      selector: this.selectorValue,
      getCaptionFromTitleOrAlt: this.captionValue,
      plugins: [lgVideo],
    });
  }

  /**
   * Disconnect gallery.
   */
  disconnect() {
    this.lg.destroy();
  }
}
