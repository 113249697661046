/**
 * Kovax
 *
 * @author Robuust
 * @author Joost Waaijer <joost@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Scrollspy Controller.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['section', 'navlink'];

  /**
   * @var {IntersectionObserver|null}
   */
  observer = null;

  /**
   * Set up intersection observer.
   */
  connect() {
    this.observer = new IntersectionObserver(this.intersectionCallback.bind(this), {
      threshold: [0, 0.5, 1],
      rootMargin: '0px 0px 0px 0px',
    });

    this.sectionTargets.forEach((section) => {
      this.observer.observe(section);
    });
  }

  /**
   * Disconnect intersection observer
   */
  disconnect() {
    this.observer.disconnect();
  }

  /**
   * Toggle class on active nav item
   *
   * @param   {Object}  activeSection
   */
  toggleActiveNav(activeSection) {
    this.sectionTargets.forEach((section, index) => {
      if (activeSection.id === section.id) {
        this.navlinkTargets[index].classList.add(...this.activeClasses);
      } else {
        this.navlinkTargets[index].classList.remove(...this.activeClasses);
      }
    });
  }

  /**
   * IntersectionCallback
   *
   * @param   {Array}  sections
   */
  intersectionCallback(sections) {
    if (sections[0].intersectionRatio > 0.50) {
      this.toggleActiveNav(sections[0].target);
    }
  }
}
