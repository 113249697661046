/* eslint-disable class-methods-use-this */
/**
 * Kovax
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for videos.
 */
export default class extends Controller {
  /**
   * Restart video on hover.
   *
   * @param {Event} e
   */
  restart(e) {
    e.target.addEventListener('mouseenter', () => {
      e.target.currentTime = 0;
      e.target.play();
    }, { once: true });
  }
}
