/**
 * Kovax
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { Swiper } from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

Swiper.use([Autoplay, Navigation]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'prev', 'next'];

  static values = {
    options: Object,
  };

  /**
   * Swiper instance.
   */
  swiper;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      grabCursor: true,
      navigation: {
        nextEl: this.hasNextTarget ? this.nextTarget : undefined,
        prevEl: this.hasPrevTarget ? this.prevTarget : undefined,
      },
      ...this.optionsValue,
    });
  }

  /**
   * Next slide.
   *
   * @param {Event} e
   */
  nextSlide(e) {
    if (e.target.tagName !== 'A') {
      this.swiper.slideNext();
    }
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;
  }
}
