/* eslint-disable class-methods-use-this */
/**
 * Kovax
 *
 * @author Robuust
 * @author Joost Waaijer <joost@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for scroll anchors.
 */
export default class extends Controller {
  /**
   * Connect handler.
   */
  connect() {
    const focusable = ['BUTTON', 'A'].includes(this.element.nodeName);

    if (focusable) {
      this.element.addEventListener('click', this.scrollTo);
    }
  }

  /**
   * Disconnect handler.
   */
  disconnect() {
    this.element.removeEventListener('click', this.scrollTo);
  }

  /**
   * Handle scroll to.
   *
   * @param {Event}  event
   */
  scrollTo(event) {
    event.preventDefault();
    const anchor = event.currentTarget.getAttribute('href').split('#')[1] || null;

    if (anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        throw Error('Scroll anchor not found');
      }
    }
  }
}
