/* eslint-disable class-methods-use-this */
/**
 * Kovax
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import * as Turbo from '@hotwired/turbo';

/**
 * Header controller.
 */
export default class extends Controller {
  static targets = ['child', 'sub', 'image'];

  /**
   * On scroll.
   */
  onScroll() {
    this.element.classList.toggle('has-scrolled', window.scrollY >= 300);
  }

  /**
   * Toggle mobile menu.
   */
  toggleMobileMenu() {
    this.element.classList.toggle('menu-is-open');
  }

  /**
   * Toggle mobile submenu.
   *
   * @param {Event} event
   */
  toggleMobileSubmenu({ target }) {
    target.closest('li').classList.toggle('is-expanded');
  }

  /**
   * Toggle children.
   *
   * @param {Event} event
   */
  toggleChildren(event) {
    const { params } = event;

    if (params.active) {
      this.subTargets.forEach((sub) => {
        sub.classList.toggle('is-hovered', event.currentTarget === sub);
      });
    }

    this.childTargets.forEach((child) => {
      child.classList.toggle('hidden', +child.dataset.index !== params.index);
    });
  }

  /**
   * Toggle image.
   *
   * @param {Event} event
   */
  toggleImage({ params }) {
    if (params.src) {
      this.imageTargets[params.image].src = params.src;
      this.imageTargets[params.image].srcset = params.srcset;
    }

    this.imageTargets[params.image]?.parentNode.parentNode.classList.toggle('hidden', !params.src);
  }

  /**
   * Switch site.
   *
   * @param {Event} e
   */
  switch(e) {
    Turbo.visit(e.target.value);
  }
}
