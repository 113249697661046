/* eslint-disable class-methods-use-this */
/**
 * Kovax
 *
 * @author Robuust
 */

import { Controller } from '@hotwired/stimulus';

const { mapkit } = window;

/**
 * Controller for contact.
 */
export default class extends Controller {
  /**
   * @return {Array}
   */
  static targets = ['map'];

  /**
   * @return {Object}
   */
  static values = {
    address: String,
  };

  /**
   * @var {Map}
   */
  map;

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect map.
   *
   * @param {HTMLElement} target
   */
  mapTargetConnected(target) {
    this.map = new mapkit.Map(target);
    this.map.cameraZoomRange = new mapkit.CameraZoomRange(250000, 20000000);

    this.setMarker();
  }

  /**
   * Destroy map.
   */
  mapTargetDisconnected() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Render marker.
   */
  async setMarker() {
    const coords = await this.getCoordinates(this.addressValue);
    const { coordinate } = coords.results[0];
    const markerClass = 'map-marker';

    const annotation = new mapkit.Annotation(
      new mapkit.Coordinate(coordinate.latitude, coordinate.longitude),
      this.getMarker.bind(this, markerClass),
      {
        animates: false,
        selected: true,
      },
    );

    this.map.showItems([annotation]);
  }

  /**
   * Get coordinates from address.
   *
   * @param {String} address
   *
   * @return {Promise}
   */
  getCoordinates(address) {
    const geocoder = new mapkit.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.lookup(address, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  /**
   * Create marker.
   *
   * @param {String} markerClass
   *
   * @return {Element}
   */
  getMarker(markerClass) {
    const element = document.createElement('div');
    element.className = markerClass;

    return element;
  }
}
