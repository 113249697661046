// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-32x32.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/favicon/android-chrome-192x192.png';
import '../assets/img/favicon/android-chrome-512x512.png';
import '../assets/img/favicon/safari-pinned-tab.svg';

// General
import '../assets/img/email-logo.png';
import '../assets/img/svg/logo.svg';
import '../assets/img/svg/logo-mark.svg';
import '../assets/img/svg/swipe-cursor.svg';
import '../assets/img/svg/loader.svg';
import '../assets/img/svg/spinner-blue.svg';
import '../assets/img/svg/spinner-orange.svg';
import '../assets/img/svg/circle.svg';

// Social media icons
import '../assets/img/instagram.webp';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-tiktok.svg';
import '../assets/img/svg/icon-youtube.svg';

// UI icons
import '../assets/img/svg/icon-arrow-right-blue.svg';
import '../assets/img/svg/icon-arrow-right-orange.svg';
import '../assets/img/svg/icon-arrow-right-circle.svg';
import '../assets/img/svg/icon-arrow-right.svg';
import '../assets/img/svg/icon-blending.svg';
import '../assets/img/svg/icon-check-badge.svg';
import '../assets/img/svg/icon-check-circle-blue.svg';
import '../assets/img/svg/icon-check-circle-orange.svg';
import '../assets/img/svg/icon-chevron-down.svg';
import '../assets/img/svg/icon-chevron-right.svg';
import '../assets/img/svg/icon-clock.svg';
import '../assets/img/svg/icon-envelope.svg';
import '../assets/img/svg/icon-finishing.svg';
import '../assets/img/svg/icon-loader.svg';
import '../assets/img/svg/icon-magnifying-glass.svg';
import '../assets/img/svg/icon-mail.svg';
import '../assets/img/svg/icon-map-pin.svg';
import '../assets/img/svg/icon-menu-minus.svg';
import '../assets/img/svg/icon-menu.svg';
import '../assets/img/svg/icon-metal-sanding.svg';
import '../assets/img/svg/icon-office.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-play.svg';
import '../assets/img/svg/icon-primer-sanding.svg';
import '../assets/img/svg/icon-putty-sanding.svg';
import '../assets/img/svg/icon-remove-imperfections.svg';
import '../assets/img/svg/icon-removing-old-layers.svg';
import '../assets/img/svg/icon-warning.svg';
import '../assets/img/svg/icon-waterproof.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
