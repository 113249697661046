/**
 * Kovax
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * @return {Object}
   */
  static values = {
    url: String,
  };

  /**
   * Reset form.
   *
   * @param {Event} e
   */
  reset(e) {
    if (e.detail.fetchResponse.response.status === 200) {
      this.element.reset();
    }
  }
}
